.App {
  text-align: "left";
  margin-left: 10px;
}
.fadeIn {
  transition: opacity 0.5s;
  opacity: 1;
}
.fadeOut {
  transition: opacity 2s;
  opacity: 0;
}
